var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"var(--v-component-base) !important"}},[_c('v-row',{staticClass:"mb-2",attrs:{"align":"end","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('el-input',{ref:"searchBar",attrs:{"suffix-icon":"el-icon-search","clearable":"","placeholder":"Search"},model:{value:(_vm.params.search),callback:function ($$v) {_vm.$set(_vm.params, "search", $$v)},expression:"params.search"}})],1)],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"dense":"","fixed-header":"","hide-default-footer":"","loading":_vm.loading,"items":_vm.countries.data,"headers":_vm.headers,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"height":"65vh","options":{
          page: _vm.page,
          itemsPerPage: _vm.params.limit,
          pageStart: 1,
          pageStop: Math.round(_vm.countries.total / _vm.params.limit),
          pageCount: Math.round(_vm.countries.total / _vm.params.limit),
          itemsLength: _vm.countries.total,
        }},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.routeToCountry},scopedSlots:_vm._u([{key:`item.flag`,fn:function({ item }){return [(item.iso2)?_c('v-avatar',{staticClass:"mr-1",attrs:{"size":"32"}},[_c('v-img',{attrs:{"contain":"","src":`https://cdn.loglive.io/flags/4x3/${item.iso2.toLowerCase()}.svg`}})],1):_vm._e()]}},{key:`item.currencyName`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.currencyName)+" ("+_vm._s(item.currencyCode)+") ")]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-center pt-2",staticStyle:{"background":"var(--v-component-base) !important"}},[_c('el-pagination',{staticStyle:{"color":"var(--v-primaryText-base)"},attrs:{"current-page":_vm.page,"page-size":_vm.params.limit,"pager-count":5,"page-sizes":[18, 25, 50, 100],"layout":!_vm.$vuetify.breakpoint.mobile
                  ? 'sizes, prev, pager, next, jumper, total'
                  : 'prev, pager, next',"total":_vm.countries.total},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event},"update:pageSize":function($event){return _vm.$set(_vm.params, "limit", $event)},"update:page-size":function($event){return _vm.$set(_vm.params, "limit", $event)}}})],1)]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }