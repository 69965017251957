<template>
  <div style="background: var(--v-component-base) !important">
    <v-row align="end" justify="end" class="mb-2">
      <v-col cols="12" sm="12" md="6" lg="4">
        <el-input ref="searchBar" suffix-icon="el-icon-search" v-model="params.search" clearable placeholder="Search">
        </el-input>
      </v-col>
    </v-row>
   
        <v-data-table dense fixed-header hide-default-footer :loading="loading" :items="countries.data"
          :headers="headers" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" height="65vh" style="cursor: pointer"
          @click:row="routeToCountry" :options="{
            page: page,
            itemsPerPage: params.limit,
            pageStart: 1,
            pageStop: Math.round(countries.total / params.limit),
            pageCount: Math.round(countries.total / params.limit),
            itemsLength: countries.total,
          }">
          <template v-slot:[`item.flag`]="{ item }">
            <v-avatar size="32" class="mr-1" v-if="item.iso2">
              <v-img contain :src="`https://cdn.loglive.io/flags/4x3/${item.iso2.toLowerCase()}.svg`"></v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.currencyName`]="{ item }">
            {{ item.currencyName }} ({{ item.currencyCode }})
          </template>

          <template v-slot:footer class="text-center">
            <!-- <v-col cols="12" class="text-center"> -->
              <div class="text-center pt-2" style="background: var(--v-component-base) !important">
                <el-pagination
                style="color: var(--v-primaryText-base)"
                :current-page.sync="page"
                :page-size.sync="params.limit"
                :pager-count="5"
                :page-sizes="[18, 25, 50, 100]"
                :layout="
                  !$vuetify.breakpoint.mobile
                    ? 'sizes, prev, pager, next, jumper, total'
                    : 'prev, pager, next'
                " :total="countries.total">
              </el-pagination>
              </div>
             
            <!-- </v-col> -->
          </template>
        </v-data-table>
  </div>
</template>
<script>
export default {
  data: () => ({
    countries: {
      total: 0,
      data: [],
    },
    headers: [
      {
        text: "Flag",
        value: "flag",
        sortable: false,
        align: "center",
      },
      {
        text: "Name",
        value: "name",
        align: "center",
      },
      {
        text: "Continent",
        value: "continent",
        align: "center",
      },
      {
        text: "Currency",
        value: "currencyName",
        align: "center",
      },
    ],
    loading: false,
    page: 1,
    sortBy: 'name',
    sortDesc: false,
    params: {
      limit: 18,
      offset: 0,
    },
    searchTimer: null,
  }),
  watch: {
    sortBy(value) {
      this.params.sortBy = value
      this.getCountries()
    },
    sortDesc(value) {
      this.params.sortDesc = value
      this.getCountries()
    },
    async page(value) {
      this.params.offset = (value - 1) * this.params.limit;
      await this.getCountries();
    },
    "params.limit": {
      immediate: true,
      async handler() {
        this.page = 1;
        await this.getCountries();
      },
    },
    "params.search": {
      async handler() {
        await this.getCountries();
      },
    },
  },
  mounted() {
    // this.focusOnSearch()
    this.$refs.searchBar.focus()
  },
  created() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.loading = true;
      this.searchTimer = setTimeout(async () => {
        this.countries = await this.$API.searchCountries({
          params: this.params,
        });
        this.loading = false;
      }, 300);
    },
    routeToCountry(item) {
      this.$router.push({ path: `/country/${item.iso2}` });
    },
    sortCountries() {

    }
  },
};
</script>
